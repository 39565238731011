<template>
  <basic-product-price
    class="product__price lead-1"
    :product="$props.product"
    :suffix="$t('product.currency')"
  />

  <div
    class="h3"
    v-text="$t(`content.products.${$props.productId}.name`)"
  />

  <div class="product__options">
    <div class="product__options">
      <div
        v-for="option in productOptions"
        :key="option.label"
        class="product__option"
      >
        <span
          class="title-2"
          v-text="option.label"
        />
        <span
          v-if="option.value"
          class="body-2"
          v-text="option.value"
        />
      </div>
    </div>

    <div class="option__doctor">
      <span
        class="title-2"
        v-text="$t('product.doctor.titleShort')"
      />
      <span
        class="body-2"
        v-text="$props.doctor"
      />
    </div>
  </div>

  <div v-html="$t(`content.products.${$props.productId}.description`)" />

  <template v-if="!$props.disableBullets">
    <ul class="product__bullets">
      <li
        v-for="(bullet, key) in $t(`content.products.${$props.productId}.bulletPoints`)"
        :key="key"
        v-text="bullet"
      />
    </ul>
  </template>
</template>

<script setup>
import { computed } from 'vue'

import useProduct from '@/hooks/useProduct'

import { BasicProductPrice } from '@/components/Basic'

// HOOKS
const { composeOptions, getCategoryIdFromProduct } = useProduct()

// INIT
defineOptions({
  inheritAttrs: false,
})
const props = defineProps({
  disableBullets: {
    type: Boolean,
    default: false,
  },
  doctor: {
    type: String,
    default: null,
  },
  product: {
    type: Object,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const productOptions = computed(() => {
  const categoryId = getCategoryIdFromProduct(props.productId)
  return composeOptions(Object.assign({}, props.product, { productId: props.productId }), props.groupId, categoryId)
})
</script>

<style scoped>
.product__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--fixed-spacing-fix-06);
  margin: 0 0 var(--spacing-san-spacing-02) 0;
}

.product__option {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-02);
}

.product__bullets {
  margin: var(--fixed-spacing-fix-04) 0;
  padding: 0 0 0 var(--spacing-san-spacing-06);
}

.option__doctor {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-02);
  flex-wrap: wrap;
}
</style>
