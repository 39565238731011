<template>
  <div class="mos-info__content">
    <div class="card__info">
      <div class="card__content">
        <div
          class="card__name title-2"
          v-text="name"
        />
        <div
          class="card__address body-2"
          v-html="
            `${props.office?.street} ${props.office?.houseNumber}<br/>${props.office?.zipCode} ${props.office?.town}`
          "
        />
      </div>

      <slot name="doctorDiscount" />
    </div>

    <div class="availability">
      <div
        v-if="isDoctorAvailable($props.office)"
        class="available"
      >
        <the-icon
          art="light"
          name="circle-check"
        />
        <span
          class="body-3"
          v-text="t('medicalofficesearch.states.all')"
        />
      </div>
      <template v-else-if="isFamilyDoctor">
        <basic-message
          v-if="$props.active"
          severity="warning"
          :closeable="false"
          :title-label="t('medicalofficesearch.states.arrangement')"
          :content="t(`medicalofficesearch.messages.office.description.${avmId}`)"
        />
        <div
          v-else
          class="arrangement"
        >
          <the-icon
            art="light"
            name="exclamation-circle"
          />
          <span
            class="body-3"
            v-text="t('medicalofficesearch.states.arrangement')"
          />
        </div>
      </template>
      <template v-else>
        <basic-message
          v-if="$props.active"
          severity="error"
          :closeable="false"
          :title-label="t(`medicalofficesearch.messages.office.title.${avmId}`)"
          :content="t(`medicalofficesearch.messages.office.description.${avmId}`)"
        />
        <div
          v-else
          class="arrangement arrangement--negative"
        >
          <the-icon
            art="light"
            name="ban"
          />
          <span
            class="body-3"
            v-text="t(`medicalofficesearch.messages.office.title.${avmId}`)"
          />
        </div>
      </template>
    </div>
    <div
      v-if="props.active"
      class="card__action"
    >
      <basic-button
        :class="buttonClass"
        class="map__submit"
        primary
        type="submit"
        size="lg"
        :label="getTranslation('form.submit')"
        @click="$emit('submit')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { isDoctorAvailable } from '@/components/MedicalSearch/utils/doctor'
import { getAVMId } from '@/components/MedicalSearch/utils/product'

import { medState } from '@/components/MedicalSearch/config/constants'

import { BasicButton, BasicMessage } from '@/components/Basic'

import TheIcon from '@/components/Container/TheIcon'

import useI18n from '@/hooks/useI18n'
import useMedicalOffice from '@/hooks/useMedicalOffice'

// HOOKS
const { t } = useI18n()
const { getTranslation } = useMedicalOffice()

// INIT
defineEmits(['submit'])
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
  office: {
    type: Object,
    required: true,
  },
  product: {
    type: Object,
    default: () => undefined,
  },
})

const avmId = computed(() => {
  return getAVMId(props.product.productId).toLowerCase()
})

const isFamilyDoctor = computed(() => {
  return props.office?.medicalOfficeStatus === medState.ACCORDING_FAMILY_DOCTOR
})

const name = computed(() => {
  return [props.office?.lastName, props.office?.firstName].join(' ')
})
</script>

<style scoped>
.mos-info__content {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-02);

  .card__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .availability {
    .available {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--secondary);

      .icon {
        font-size: 20px;
        color: var(--secondary);
      }
    }

    .message {
      border: 1px solid var(--outline-variant);
      margin: var(--fixed-spacing-fix-04) 0;
    }
  }

  .arrangement {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--colors-extended-a-500);

    .icon {
      font-size: 20px;
      color: var(--colors-extended-a-500);
    }

    &--negative,
    &--negative .icon {
      color: var(--error);
    }
  }

  .card__action {
    gap: var(--fixed-spacing-fix-02);
    .map__submit {
      float: right;
    }
  }
}

.map__submit {
  width: 100%;
}

:root .gm-style-iw-d {
  @media (--v-large) {
    .card__action {
      display: none;
    }
  }
}

@media (--v-medium) {
  .button {
    width: auto;
  }
}
</style>
