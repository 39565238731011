<template>
  <!-- prettier-ignore -->
  <basic-modal
    :id="`replaceModal_${fromProduct?.productId}-${toProduct?.productId}`"
    ref="modalRef"
    type="transactional"
    :modal-title="$t('product.replace.title')"
    :primary-label="$t('product.replace.confirm')"
    @click-primary="confirm"
    @click-secondary="cancel"
  >
    <div class="replace">
      <div class="replace__products">
        <div class="replace__product">
          <div
            class="title-2"
            v-text="$t('product.replace.selected')"
          />

          <replace-product
            v-if="fromProduct"
            :product-id="fromProduct.productId"
            :product="fromProduct"
          />
        </div>

        <div class="replace__product replace__product--selected">
          <div
            class="title-2"
            v-text="$t('product.replace.selectable')"
          />

          <replace-product
            v-if="toProduct"
            :product-id="toProduct.productId"
            :product="toProduct"
          />
        </div>
      </div>
    </div>
  </basic-modal>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue'

import { events$, loading$ } from '@/services'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useSnackbar from '@/hooks/useSnackbar'

import { BasicModal } from '@/components/Basic'
import ReplaceProduct from '@/components/Product/Replace/ReplaceProduct'

import { EVENT_PRODUCT } from '@/config/events'
import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { getCategoryIdFromProduct, isUpgrade } = useProduct()
const { addProductToOvpBasket } = useProductPersons()
const { changeProductOption, getProduct, updateProductForPerson } = usePersonSpecificProduct()
const { addToast } = useSnackbar()

// DATA
const modalRef = ref()
const person = ref(null)
const fromProduct = ref(null)
const source = ref(null)
const toProduct = ref(null)

// METHODS
function cancel() {
  modalRef.value.close()

  person.value = null
  fromProduct.value = null
  toProduct.value = null
  source.value = null
}

async function confirm() {
  loading$.start()

  const isUpdate = fromProduct.value.productId === toProduct.value.productId

  try {
    const mode = isUpgrade(toProduct.value.productId) ? 'ADD' : 'REPLACE'
    let basket = updateProductForPerson({ personId: person.value.personId, product: toProduct.value })
    basket.persons.find(p => p.personId === person.value.personId).products.products[
      fromProduct.value.productId
    ].selected = false
    basket = await addProductToOvpBasket(person.value.personId, toProduct.value.productId, { basket, mode })
    basket = await basketStore.updateOvpBasket(basket)

    if (!isUpdate) {
      events$.emit(EVENT_PRODUCT.REMOVED, {
        basket,
        categoryId: getCategoryIdFromProduct(fromProduct.value.productId),
        personId: person.value.personId,
        productId: fromProduct.value.productId,
        source: source.value,
        silent: true,
      })
    }

    events$.emit(isUpdate ? EVENT_PRODUCT.CHANGED : EVENT_PRODUCT.ADDED, {
      basket,
      categoryId: getCategoryIdFromProduct(toProduct.value.productId),
      personId: person.value.personId,
      productId: toProduct.value.productId,
      source: source.value,
    })

    loading$.end()

    cancel()
  } catch (error) {
    loading$.failed()

    error.messages.forEach(message => {
      addToast({
        type: NOTIFICATION.ERROR,
        text: message,
      })
    })
  }
}

async function openModal(data) {
  loading$.start()

  try {
    person.value = data.person
    fromProduct.value = Object.assign({}, getProduct(data.person.personId, data.fromProductId), {
      productId: data.fromProductId,
    })
    source.value = data.source

    if (data.simulate) {
      toProduct.value = await changeProductOption({
        option: fromProduct.value.prices.find(p => p.selected),
        personId: data.person.personId,
        product: ReactivityUtil.clone(data.toProduct),
      })
    } else {
      toProduct.value = data.toProduct
    }

    await nextTick()

    modalRef.value.open()

    loading$.end()

    events$.emit(EVENT_PRODUCT.REPLACE_DISPLAYED)
  } catch {
    loading$.failed()
  }
}

// LIFECYCLE EVENTS
onMounted(() => {
  events$.on(EVENT_PRODUCT.REPLACE_OPEN, data => openModal(data))
})
</script>

<style scoped>
.replace {
  .replace__products {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-san-spacing-06);
    margin: var(--spacing-san-spacing-06) 0 0 0;
  }

  .replace__product {
    margin: var(--spacing-san-spacing-03) 0 0 0;
    border: 1px solid var(--outline-variant);
    border-radius: var(--radius-border-radius-03);
    padding: var(--fixed-spacing-fix-06) var(--dotcom-responsive-spacing-res-xs) var(--fixed-spacing-fix-07)
      var(--dotcom-responsive-spacing-res-xs);

    &--selected {
      border-color: var(--surface-ext-b-low);
      background-color: var(--surface-ext-b-low);
      padding: var(--fixed-spacing-fix-06) var(--dotcom-responsive-spacing-res-xs) var(--fixed-spacing-fix-07)
        var(--dotcom-responsive-spacing-res-xs);
      border-radius: var(--radius-border-radius-03);
    }
  }
}

@media (--v-medium) {
}

@media (--v-large) {
  .replace {
    .replace__products {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(6, minmax(0, min-content));
      column-gap: var(--spacing-san-spacing-06);
    }

    .replace__product {
      display: grid;
      grid-row: 1 / -1;
      grid-template-columns: 1fr;
      grid-template-rows: subgrid;
      gap: 0;
    }
  }
}

@media (--v-wide) {
}

@media (--v-ultra) {
}

@media (--v-max) {
}
</style>
