import events$ from '@/services/Events'

import { logInfo, logWarning } from '@/helpers/Logger'

import { EVENT_CHAT, EVENT_LOGIN, EVENT_URL } from '@/config/events'
import { CHANNEL } from '@/config/constants'

export default function useUrl() {
  // METHODS
  function sanitizePrefillParams(params) {
    const whitelistKeys = [
      'firstName',
      'gender',
      'dateOfBirth',
      'primeRegion',
      'locationNumber',
      'zipCode',
      'zipCodeAddon',
    ]

    const decodedParams = {}
    const encodedData = params.get('data')
    atob(encodedData)
      .split(';')
      .filter(i => i.length > 0)
      .forEach(item => {
        const __data = item.split('=')
        if (!whitelistKeys.includes(__data[0])) {
          throw Error('invalid param')
        }

        decodedParams[__data[0]] = __data[1].trim()
      })

    if (whitelistKeys.length !== Object.keys(decodedParams).length) {
      throw Error('invalid param')
    }

    return decodedParams
  }

  function _openLoginModal(urlParams) {
    const payload = {
      step: urlParams.get('step'),
      platform: urlParams.get('platform'),
    }

    // `platform` is only set, when coming from PORTAL
    payload.channel = payload.platform ? CHANNEL.PORTAL : CHANNEL.OVP

    // @note: as we currently don't know, which login-modal we open,
    // we open all the login-modals on a page via the open-all prop
    payload.openAll = true

    // @note: as the a11dialog is instantiate after a next-tick, we have to wait for some time
    // before triggering to open the modal
    const t0 = performance.now()
    const msToRun = 10000
    const modalInterval = setInterval(() => {
      const isModalInDOM = document.querySelector('[id*="login-modal"]')
      if (isModalInDOM) {
        clearInterval(modalInterval)
        events$.emit(EVENT_LOGIN.OPEN, payload)
      }
      if (performance.now() - t0 > msToRun) {
        clearInterval(modalInterval)
        window.ineum('reportError', 'No login modal found')
      }
    }, 100)
  }

  function react() {
    const start = window.location.hash.indexOf('?')
    const query = window.location.hash.substring(start)
    const params = new URLSearchParams(query ? query : window.location.search)

    const open = params.get('open')
    const goto = params.get('goto')

    if (open) {
      const components = open.split(',')
      components.forEach(component => {
        switch (component) {
          case 'chat':
            logInfo('OPEN CHAT')
            events$.on(EVENT_CHAT.LOADED, () => {
              events$.emit(EVENT_CHAT.OPEN)
            })
            break

          case 'login': {
            _openLoginModal(params)
            break
          }

          default:
            logWarning(`useUrl can not open unknown option: ${component}`)
        }
      })
    } else if (goto) {
      events$.emit(EVENT_URL.GOTO, {
        target: goto,
      })
    }
  }

  return {
    react,
    sanitizePrefillParams,
  }
}
