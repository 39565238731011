<template>
  <!-- prettier-ignore -->
  <div
    class="input"
    :class="classes"
  >
    <div
      v-if="isTestEnv"
      class="code__info"
    >
      <basic-message
        class="input__test-hint"
        severity="warning"
        title-label="OTP will be sent to the channels in Slack <a target='_blank' href='slack://channel?team=T19FP6GTF&id=C037W6U89B4'>#dev-sms-tan</a> and Teams <a target='_blank' href='https://teams.microsoft.com/l/channel/19%3AW7s2UcAhiC9M47OsiOaY8_MI2JNS-jaDGkxIjI4HGMs1%40thread.tacv2/dvp?groupId=0d0d8e1e-e52d-4c69-947f-6c46b871f87a&tenantId=a95da8fa-882c-4804-aeb4-81388d204ea8&ngc=true&allowXTenantAccess=true'>#tokens</a>"
      />
    </div>
    <div class="code__content">
      <div
        class="code__inputs__title body-1"
        v-text="autoLabel"
      />
      <div class="code__inputs">
        <div
          ref="codeElementsRef"
          class="input__elements"
        >
          <div
            v-for="(nr, index) in props.length"
            :key="nr"
            class="input__element"
          >
            <basic-digit-input
              :key="`digit-${index}`"
              :ref="el => (digitInputRef[index] = el)"
              v-model="values[index]"
              :name="`${props.name}-${nr}`"
              @input="event => fill(event, index)"
              @keydown="event => handleKeydown(event, index)"
            />
          </div>
        </div>
      </div>
      <div
        class="code__inputs__hint caption-regular"
        v-text="autoHint"
      />
    </div>
    <slot />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'

import useI18n from '@/hooks/useI18n'

import BasicDigitInput from '@/components/Basic/DigitInput'
import BasicMessage from '@/components/Basic/Message'

// HOOKS
const { t } = useI18n()

// INIT
const model = defineModel({ type: String })
const props = defineProps({
  length: {
    type: Number,
    default: 4,
  },

  name: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    default: '',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },
})
const isTestEnv = import.meta.env.VITE_ENV_KEY !== 'PRD'

// DATA
const codeElementsRef = ref(null)
const digitInputRef = ref({})
const values = ref({})

// COMPUTED
const autoLabel = computed(() => {
  let str = props.label
  if (isRequired.value) {
    str += ' *'
  }

  return str
})

const autoHint = computed(() => {
  if (isInvalid.value) {
    const error = props.v?.$errors[0]?.$validator
    if (error) return t(`form.error.${error}`)
  }
  return props.supportingText
})

const classes = computed(() => {
  return {
    'input--invalid': isInvalid.value,
  }
})

const isInvalid = computed(() => {
  return props.v?.$dirty && props.v?.$invalid
})

const isRequired = computed(() => {
  return !!props.v?.required
})

// METHODS
function clear() {
  ;[...Array(props.length).keys()].forEach(index => {
    values.value[index] = null
  })
}

function fill(event, index) {
  if (event.data) {
    values.value[index] = event.data

    const nextEl = codeElementsRef.value.children[index + 1]

    // has follow-up field
    if (nextEl) {
      goto(index + 1)

      // must have been the last one
    } else {
      const input = []
      Object.keys(values.value).forEach(value => input.push(values.value[value]))
      model.value = input.join('')
    }

    // field was cleared
  } else {
    values.value[index] = null
    model.value = null
  }
}

function goto(index) {
  digitInputRef.value[index].digitInputRef.focus()
}

function handleKeydown(event, index) {
  switch (true) {
    case event.shiftKey:
      event.preventDefault()
      break
    case event.keyCode === 8:
    case event.keyCode === 46:
      if (index > 0) {
        window.setTimeout(() => {
          goto(index - 1)
        }, 50)
      }
      break
    case (event.keyCode < 48 || event.keyCode > 57) && // Not between 0-9 on the main keyboard
      (event.keyCode < 96 || event.keyCode > 105) && // Not between 0-9 on the numpad
      event.keyCode !== 8 && // Not Backspace
      event.keyCode !== 9 && // Not Tab
      event.keyCode !== 13 && // Not Enter (submit action)
      event.keyCode !== 46 && // Not Delete
      event.keyCode !== 86: // Not 'V' (paste action)
      event.preventDefault()
  }
}

function handlePaste(event) {
  const paste = (event.clipboardData || window.clipboardData).getData('text')
  const splitPaste = paste?.split('')
  event.preventDefault()

  if (paste && paste.length === props.length) {
    ;[...Array(props.length).keys()].forEach(index => {
      values.value[index] = splitPaste[index]
    })
    model.value = paste
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  clear()

  // register paste-handler on the first input field
  digitInputRef.value[0].digitInputRef.addEventListener('paste', event => handlePaste(event))
})

// WATCHER
watch(model, value => {
  if (!value) clear()
})
</script>

<style scoped>
.code__inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input__elements {
  display: flex;
  gap: var(--fixed-spacing-fix-04);
}

.input__element {
  width: 44px;
  height: 48px;
}

.code__inputs__hint {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--fixed-spacing-fix-04);
  height: var(--spacing-san-spacing-12);
  overflow: hidden;
}

.input--invalid {
  .code__inputs__title,
  .code__inputs__hint {
    color: var(--error);
  }
}

.code__info {
  margin: 0 0 var(--dotcom-responsive-spacing-res-l) 0;
}
</style>
